import {IUser} from '../../mainTypes';

const initState: IUser = {
  userId: '',
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  lanId: '',
  isAdmin: '',
  token: '',
  isUserNotFound: true,
  isLoaded: true,
  isAuthorized: true,
};

export default (state: IUser = initState, action: any) => {
  switch (action.type) {
    case 'UPDATE_LOGON_USER':
      const user: IUser = action.payload;
      return {...state,
        userId: user.userId,
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.fullName,
        email: user.email,
        lanId: user.lanId,
        isAdmin: user.isAdmin,
        isUserNotFound: false,
        isLoaded: true,
      };
    case 'UPDATE_USER_TOKEN':
      return {...state, token: action.payload};
    case 'UPDATE_IS_USER_NOT_FOUND':
      return {...state, isUserNotFound: action.payload};
    case 'UPDATE_USER_IS_AUTHORIZED':
      return {...state, isAuthorized: action.payload};
    default:
      return state;
  }
};
