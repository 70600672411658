import {IIdeaState} from '../../mainTypes';

const initState: IIdeaState = {
  data: [],
  isIdeasLoaded: false,
  isLikesLoaded: false,
  isTeamsLoaded: false,
};

export default (state = initState, action: any) => {
  switch (action.type) {
    case 'GET_IDEAS_FROM_API':
      return {...state, data: action.payload, isIdeasLoaded: true};
    case 'UPDATE_LIKES':
      const updateLikes = state.data;
      updateLikes.forEach((idea) => {
        idea.likes = action.payload.filter((like) => like.ideaId == idea.id).length;
        idea.likesDetail = action.payload.filter((like) => like.ideaId == idea.id);
      });
      return {...state, data: updateLikes, isLikesLoaded: true};
    case 'UPDATE_TEAMS':
      const updateTeams = state.data;
      updateTeams.forEach((idea) => {
        idea.teamsDetail = action.payload.filter((team) => team.ideaId == idea.id);
      });
      return {...state, data: updateTeams, isTeamsLoaded: true};
    case 'UPDATE_SINGLE_IDEA':
      const newIdeas = state.data;
      const foundIndex = newIdeas.findIndex((x) => x.id == action.payload.id);
      newIdeas[foundIndex] = action.payload;
      return {...state, data: newIdeas};
    case 'ADD_SINGLE_IDEA':
      const ideaToAdd = state.data;
      ideaToAdd.push(action.payload);
      return {...state, data: ideaToAdd};
    default:
      return state;
  }
};
