interface IApp {
  isLoading: boolean,
  isImageUploadCompleted: boolean,
}

const initState:IApp = {
  isLoading: true,
  isImageUploadCompleted: false,
};

export default (state:IApp = initState, action:any) => {
  switch (action.type) {
    case 'UPDATE_IS_LOADING':
      return {...state, isLoading: action.payload};
    default:
      return state;
  }
}
;
