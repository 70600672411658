import {IImageState} from '../../mainTypes';

const initState: IImageState = {
  data: [],
  isImageLoaded: false
};

export default (state = initState, action: any) => {
  switch (action.type) {
    case 'GET_IMAGES_FROM_API':
      return {...state, data: action.payload, isImageLoaded: true};
    default:
      return state;
  }
};
