import {combineReducers} from 'redux';
import app from './app.reducer';
import user from './user.reducer';
import ideas from './ideas.reducer';
import images from './images.reducer';

export default combineReducers({
  app,
  user,
  ideas,
  images
});
