import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'svgxuse';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './Store/store';
import * as serviceWorker from './serviceWorker';
import '@exxonmobil/react-unity/dist/unity.css';
import './index.scss';

// MSAL imports
import { loginRequest } from './authConfig';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult, InteractionType } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
export const msalInstance = new PublicClientApplication(msalConfig);

// MSAL Account
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const App = lazy(() => import('./App/App'));

ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
        <Suspense fallback="">
          <App />
        </Suspense>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
